.c-subscription-cards__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 6rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}
