/**
* @file
* Checkbox styling
*
* @example
* <span class="c-checkbox">
*  <input type="checkbox" class="c-checkbox__input" id="checkbox-id" />
*  <label class="c-checkbox__label" for="checkbox-id">Checkbox label</label>
* </span>
 */

.c-checkbox {
  display: block;
  margin-bottom: 1rem;
}

.c-checkbox__input {
  opacity: 0;
  width: 0;
  height: 0;
  filter:alpha(opacity=0);
  overflow: hidden;
}

.c-checkbox__label {
  margin: 0;
  line-height: $lh--form;
  position: relative;
  padding-left: 33px;
  cursor: pointer;

  &:before {
    @include icon-styles;
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid $color-grey;
    box-shadow: $box-shadow--inset;
    transition: $default-transition;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding-top: 2px;
  }
}

.c-checkbox__input:checked + .c-checkbox__label {
  &:before {
    content: icon-char(checkmark);
    background: $color-green;
    border-color: #000;
    box-shadow: none;
  }
}

.c-checkbox__input:disabled + .c-checkbox__label {
  cursor: default;
  color: $color-grey--light;

  &:before {
    border-color: $color-grey--light;
  }
}
