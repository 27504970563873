/**
* @file
* Basic styling for input fields & text areas
*
* @example
* <input type="text" class="c-form-control" />
* <textarea type="text" class="c-form-control"></textarea>
 */

::placeholder {
  color: $color-grey--light;
}

.c-form-control {
  display: block;
  width: 100%;
  background: #fff;
  line-height: $lh--form;
  padding: 13px 15px 12px;
  border: 1px solid $color-grey;
  box-shadow: $box-shadow--inset;
  transition: $default-transition;

  &:focus,
  &.is-active {
    outline: none;
    border-color: #000;
  }

  &:disabled,
  &.is-disabled {
    border-color: $color-grey--light;
  }

  &:read-only {
    outline: none;
    color: $color-grey;
    background-color: $color-grey--lightest;
  }

  &:read-only:focus {
    border-color: $color-grey;
  }

  &--block {
    display: block;
    width: 100%;
  }
}

