/**
* @file
* Styling for the header component
*
* @example
*  <div class="c-site-header__branding">
*    <div class="c-site-branding">
*      <a href="#" class="c-site-branding__logo">
*        <img src="..." alt="...">
*      </a>
*    </div>
*  </div>
*
 */
.c-site-branding {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 67px;
  max-height: 67px;
}

.c-site-branding__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  min-height: 29px;

  img {
    display: block;
    height: 100%;
    max-height: 22px;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
      max-height: 35px;
    }
  }

  .c-site-branding__anniversary-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 3;
    min-height: 29px;

    img {
      display: block;
      height: 100%;
      max-height: 22px;
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        max-height: 120px;
      }
    }
  }
}
