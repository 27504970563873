/**
* @file
* This file contains styling for specific classes on the body or page level.
* According to the different types, different gradients will be used on buttons and in headers
*
* @example
* <body class="theme theme--red">
 */
.theme--red {
  .c-site-header:before,
  .c-button:after,
  .c-article-progress__bar:after,
  .c-site-footer:before {
    background: $gradient-yellow-red;
  }
}
