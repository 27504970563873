.c-ticket {
  padding-top: 0;
  &__pricing {
    &_row {
      span {
        flex: auto;
      }
    }
    &_value {
      font-size: 1em;
    }
  }
  &__subscription {
    &-name {
      display: block;
      padding-bottom: 1rem;
      font-weight: bold;
    }
  }

  hr {
    height: 0;
    border-top: {
      width: 1px;
      color: $color-grey--lighter;
      style: solid;
    }
  }
}

.c-ticket--corporate-abo-padding {
  @include media-breakpoint-up(md) {
    margin-top: 91.5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 84.5rem;
  }
}

.c-ticket--adfo-padding {
  @include media-breakpoint-up(md) {
    margin-top: 104.6rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 100.9rem;
  }
}

.c-ticket__body {
  background: $color-white;
}

.c-ticket__subscription_include {
  position: relative;
  padding-left: 2rem;
  font-family: "EB Garamond", serif;
  padding-bottom: 1rem;
  font-size: 1.7rem;

  &:before {
    @extend %icon;
    content: icon-char(checkmark);
    color: $color-green;
    position: absolute;
    top: 0.4rem;
    left: 0;
    font-size: 1.4rem;
  }
}

.c-ticket__notice {
  padding: 0;
  margin-top: 1rem;
  text-align: center;
  & + .c-ticket__notice {
    margin-top: 0;
  }
}
