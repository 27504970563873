/**
* @file
* Basic styling for select boxes
*
* @example
* <select name="select" id="select" class="c-select">
*   <option value="">Selecteer je functie</option>
*   <option value="1">Management</option>
*   <option value="2">Development</option>
*   <option value="3">Creative</option>
* </select>
 */

.c-select {
  display: inline-block;
  border-radius: 0;
  background: #fff no-repeat center right 10px url('../../../images/icons/arrow-alt--down.svg');
  background-size: 12px;
  width: 100%;
  padding: 13px 15px 12px;
  border: 1px solid $color-grey;
  box-shadow: $box-shadow;
}

