/**
* @file
* Fonts and font variables should be defined here
*/
@font-face {
    font-family: 'Brown Pro';
    src:
        url('../../fonts/lineto-brown-pro-regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/lineto-brown-pro-regular.woff') format('woff'),
        url('../../fonts/lineto-brown-pro-regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Brown Pro';
    src:
        url('../../fonts/lineto-brown-pro-light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/lineto-brown-pro-light.woff') format('woff'),
        url('../../fonts/lineto-brown-pro-light.woff2') format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'Brown Pro';
    src:
        url('../../fonts/lineto-brown-pro-bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/lineto-brown-pro-bold.woff') format('woff'),
        url('../../fonts/lineto-brown-pro-bold.woff2') format('woff2');
    font-weight: 700;
}

/* Font families */
$ff-serif: 'EB Garamond', serif;
$ff-sans-serif: 'Brown Pro', sans-serif;
$ff-monospace: 'Roboto Mono', monospace;

/* Font sizes */
$fs: 1.5rem;
$fs--small: (
    xs: 1.1rem,
    sm: 1.2rem
);
$fs--medium: (
    xs: 1.5rem,
    sm:1.7rem
);
$fs--p: (
    xs: 1.7rem,
    sm: 2.1rem
);
$fs--h1: 3.5rem;
$fs--h2: 2.5rem;
$fs--h3: (
    xs: 1.7rem,
    sm: 1.9rem
);
$fs--label: 1.2rem;
$fs--tag: 1.1rem;
$fs--slogan: 1.9rem;
$fs--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$fs--article-header-lead: (
    xs: 2rem,
    sm: 2.5rem
);
$fs--article-header-meta: $fs--small;
$fs--teaser-label: $fs--small;
$fs--teaser-day: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--teaser-month: (
    xs: 0.9rem,
    sm: 1.2rem
);
$fs--teaser-title: (
    xs: 1.8rem,
    sm: 2.5rem
);
$fs--user-description: $fs--small;
$fs--caption: $fs--small;
$fs--quote-quote: (
    xs: 1.4rem,
    sm: 1.8rem
);
$fs--quote-author: $fs--small;
$fs--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$fs--comment-author: $fs--medium;
$fs--comment-date: $fs--small;
$fs--highlight: 1.9rem;
$fs--whitepaper-link-title: (
    xs: 1.8rem,
    sm: 1.9rem
);
$fs--whitepaper-link-number: (
    xs: 2rem,
    sm: 3rem
);
$fs--vacancy-link-title: (
    xs: $fs,
    sm: 1.6rem
);
$fs--dossier-link: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--pagination: (
    xs: 1.4rem,
    sm:1.5rem
);
$fs--form-help: (
    xs: 1.4rem,
    sm: 1.5rem
);
$fs--cross-link-title: $fs--medium;
$fs--page-header-title: (
    xs: 2.1rem,
    sm: 3rem
);

/* Line heights */
$lh: 2rem;
$lh--small: (
    xs: 1.5rem,
    sm:2rem
);
$lh--medium: (
    xs: 2.5rem,
    sm: 3rem
);
$lh--label: 1.5rem;
$lh--form: 1.8rem;
$lh--p: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--h1: 4.2rem;
$lh--h2: 3.2rem;
$lh--h3: $lh--medium;
$lh--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$lh--article-header-lead: (
    xs: 2.5rem,
    sm: 2.5rem
);
$lh--article-header-meta: $lh--small;
$lh--teaser-label: $lh--small;
$lh--teaser-title: (
    xs: 2rem,
    sm: 3rem
);
$lh--user-description: $lh--small;
$lh--caption: $lh--small;
$lh--quote-quote: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--quote-author: $lh--small;
$lh--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$lh--comment-author: 3rem;
$lh--comment-date: $lh--small;
$lh--highlight: 2.5rem;
$lh--whitepaper-link-title: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--whitepaper-link-number: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--vacancy-link-title: $lh--medium;
$lh--dossier-link: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--form-help: (
    xs: 1.7rem,
    sm: 1.8rem
);
$lh--cross-link-title: $lh--medium;
$lh--page-header-title: (
    xs: 3rem,
    sm: 4rem
);

/* Letter spacings */
$ls--label: 1px;
