/**
* @file
* The main css file,
* it should only import partials and
* never include any code
*/

/* Styling from @sijthoffmedia/smg-assets */
@import '@sijthoffmedia/smg-assets/scss/adformatie/_style.scss';

/* Universal styling */
@import "common/universal";

/*
* Basics styling
* These files should contain the styling for
* general layout, input elements, buttons, etc.
*/
@import "adformatie/basics/grid";
@import "adformatie/basics/typography";
@import "adformatie/basics/iconfont";
@import "adformatie/basics/button";
@import "adformatie/basics/block";
@import "adformatie/basics/form-label";
@import "adformatie/basics/form-control";
@import "adformatie/basics/footnote";
@import "adformatie/basics/checkbox";
@import "adformatie/basics/radio-button";
@import "adformatie/basics/select";
@import "adformatie/basics/tab-group";
@import "adformatie/basics/display";
@import "adformatie/basics/link";
@import "adformatie/basics/form-buttons";
@import "adformatie/basics/form-row";
@import "adformatie/basics/page";
@import "adformatie/basics/form-spacer";
@import "adformatie/basics/form-validation";
@import "adformatie/basics/page-title";
@import "adformatie/basics/toggle";
@import "adformatie/basics/ie11";
@import "adformatie/basics/faq";

/*
* Components styling
* The files should contain the styling for
* components like header, footer, article, etc.
*/
@import "common/site-footer";
@import "common/ticket";

@import "adformatie/components/date-select";
@import "adformatie/components/site-header";
@import "adformatie/components/site-branding";
@import "adformatie/components/page-header";
@import "adformatie/components/register-footnote";
@import "adformatie/components/register-title";
@import "adformatie/components/request-form";
@import "adformatie/components/form";
@import "adformatie/components/form-footer";
@import "adformatie/components/step-indicator";
@import "adformatie/components/step";
@import "adformatie/components/pricing-block";
@import "adformatie/components/pricing-option";
@import "adformatie/components/pricing-label";
@import "adformatie/components/price";
@import "adformatie/components/price-toggle";
@import "adformatie/components/dashboard";
@import "adformatie/components/account-link";
@import "adformatie/components/postcode-check";
@import "adformatie/components/paragraph";
@import "adformatie/components/banner";
@import "adformatie/components/flash-messages";
@import "adformatie/components/signup-header";
@import "adformatie/components/ticket";
@import "adformatie/components/signup-title";
@import "adformatie/components/info-page";
@import "adformatie/components/subscription-info";
@import "adformatie/components/inline-logo";
@import "adformatie/components/password-reset";
@import "adformatie/components/signup-landing";
@import "adformatie/components/value-proposition";
@import "adformatie/components/subscription-cards";
@import "adformatie/components/subscription-card";
@import "adformatie/components/toggle-slider";
@import "adformatie/components/subscription-overview";
@import "adformatie/components/membership-type-block";
@import "adformatie/components/membership-type";
@import "adformatie/components/group-label";
@import "adformatie/components/select-account";

/**
* Theme styling
* This file contains styling on page level in which nested elements
* have specific styles according to their parents.
* Be extra careful with specificity in here!
 */
@import "adformatie/theme/default";
@import "adformatie/theme/red";
@import "adformatie/theme/green";
@import "adformatie/theme/blue";
@import "adformatie/theme/purple";

/* Use this file for hotfixes and refactor later */
@import "adformatie/shame";
