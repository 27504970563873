.c-value-proposition {
    background-color: $color-white;
    padding: 4rem 0;
    &:not(:last-child) {
        border-bottom: 1px solid $color-grey--light;
    }

    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }
}
