.c-form {
  margin-bottom: 2rem;
}

.c-form__header {
  margin-bottom: 2rem;
  font-size: 3rem;
}

.c-form__body {
  background: $color-white;
  padding: 2.5rem 1.5rem;
  border: 1px solid $color-grey--lighter;
}

.c-form__button {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
    min-width: 23rem;
  }
}

.c-form__button--wide {
  width: 100%;
}

.c-form__info {
  color: $color-blue;
  margin-bottom: 2rem;
  border: 1px solid $color-blue;
  padding: 1rem 2rem;
}

.c-form__warning {
  color: $color-orange;
  margin-bottom: 2rem;
  border: 1px solid $color-orange--light;
  padding: 1rem 2rem;
}
