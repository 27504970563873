.c-signup-title {
  display: flex;
  align-items: flex-end;
  margin: 4rem 0 2rem 0;
  font-family: "Brown Pro", sans-serif;
  font-weight: normal;
}

.c-signup-title--counter {
  &::before {
    counter-increment: signup-steps;
    content: counter(signup-steps) '. ';
    margin-right: 5px;
  }
}

.c-signup-title__change-button {
  margin-left: auto;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-decoration: underline;
  cursor: pointer;
}
