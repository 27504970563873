/**
* @file
* All colors used on the website are defined here
*/

/* Base colors */
$color-black: #111111;
$color-white: #ffffff;
$color-yellow: #ffdd02;
$color-blue: #006eb8;
$color-green: #01967f;
$color-red: #e90027;
$color-orange: #e95a00;

/* Secondary colors */
$color-blue--dark: #005085;
$color-purple: #9265a8;
$color-pink: #feebee;
$color-orange--light: lighten($color-orange, 15%);

/* Grey colors */
$color-grey: #666666;
$color-grey--light: #b3b3b3;
$color-grey--lighter: #d9d9d9;
$color-grey--lightest: #f2f2f2;
$color-grey--dark: #1a1a1a;

/* Gradients */
$gradient-yellow-red: linear-gradient(90deg, $color-yellow, $color-red);
$gradient-yellow-blue: linear-gradient(90deg, $color-yellow, $color-blue);
$gradient-yellow-green: linear-gradient(90deg, $color-yellow, $color-green);
$gradient-yellow-purple: linear-gradient(90deg, $color-yellow, $color-purple);
$gradient-rainbow: linear-gradient(90deg, #e90027 0%, #ff8000 25%, #ffdd02 50%, #01967f 75%, #006eb8 100%);
$gradient-white-transparant: linear-gradient(-180deg, rgba($color-white, 0) 0%, $color-white 100%);
$gradient-dark-grey-black: linear-gradient(-90deg, #262626 1%, #000000 100%);
$gradient-transparent-grey: linear-gradient(90deg, rgba(237, 237, 237, 0) 0%, #ededed 100%);

/* vendor colors */
$color-social--facebook: #3b5998;
$color-social--twitter: #55acee;
$color-social--linkedin: #007bb5;
$color-social--surfnet: #fedb00;

/* Parsley colors */
$field-success-color: $color-green;
$field-error-color: $color-red;
