.c-subscription-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30rem;
    background-color: $color-white;
    margin-bottom: 3.4rem;

    &:not(:has(.c-subscription-card__promotion)) {
        border-radius: 2rem 2rem 0 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-right: 4rem;
        margin-bottom: 0;
        min-height: 57rem;

        &:last-child {
            margin-right: 0;
        }
    }

    ul:has(.c-ticket__subscription_include) {
        padding: 2rem 2rem 3rem;
    }
}

.c-subscription-card__promotion {
    height: 3.4rem;
    line-height: 3.4rem;
    text-align: center;
    font-size: 1.8rem;
    color: $color-white;
    background-color: $color-blue;
    border-radius: 2rem 2rem 0 0;

    @include media-breakpoint-up(md) {
        position: absolute;
        top: -3.4rem;
        left: 0;
        right: 0;
    }

}

.c-subscription-card__header {
    position: relative;
    padding: 2rem 0;
    font-family: $ff-sans-serif;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $color-grey--light;
    }
}

.c-subscription-card__toggle-slider {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
}

.c-subscription-card__price-container {
    margin-top: auto;
    display: flex;
    justify-content: center;
}

.c-subscription-card__price-container--before-price {
    h4 {
        margin-right: 0.4rem;
    }
}

.c-subscription-card__price {
    font-size: 4rem;
    line-height: 3rem;
    font-weight: 400;
    margin-right: 0.6rem;
}

.c-subscription-card__price-suffix {
    font-size: 1.4rem;
    line-height: 120%;
    color: $color-grey;
}

.c-subscription-card__cta {
    margin: 3rem 2rem 1rem;
}

.c-subscription-card__label {
    min-height: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.2rem;
    color: $color-grey--light;
}
