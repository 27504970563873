.c-footnote {
    text-align: center;

    h1 {
        font-family: "Brown Pro", sans-serif;
        margin-bottom: 25px;
    }

    .c-button {
        margin: 0 auto;
    }
}
