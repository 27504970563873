@font-face {
    font-family: "icon-font";
    src:    url("~@sijthoffmedia/smg-assets/fonts/icon-font.eot?47f7c070e7d22980ba34dc630073043a?#iefix") format("embedded-opentype"),
            url("~@sijthoffmedia/smg-assets/fonts/icon-font.woff2?47f7c070e7d22980ba34dc630073043a") format("woff2"),
            url("~@sijthoffmedia/smg-assets/fonts/icon-font.woff?47f7c070e7d22980ba34dc630073043a") format("woff"),
            url("~@sijthoffmedia/smg-assets/fonts/icon-font.ttf?47f7c070e7d22980ba34dc630073043a") format("truetype"),
            url("~@sijthoffmedia/smg-assets/fonts/icon-font.svg?47f7c070e7d22980ba34dc630073043a#icon-font") format("svg");
}

@mixin icon-styles {
    font-family: "icon-font";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";
        @if $filename == arrow--down {
            $char: "\f101";
        }
        @if $filename == arrow--left {
            $char: "\f102";
        }
        @if $filename == arrow--right {
            $char: "\f103";
        }
        @if $filename == arrow--up {
            $char: "\f104";
        }
        @if $filename == arrow-alt--down {
            $char: "\f105";
        }
        @if $filename == arrow-alt--left {
            $char: "\f106";
        }
        @if $filename == arrow-alt--right {
            $char: "\f107";
        }
        @if $filename == arrow-alt--up {
            $char: "\f108";
        }
        @if $filename == arrow-bold--down {
            $char: "\f109";
        }
        @if $filename == arrow-bold--left {
            $char: "\f10a";
        }
        @if $filename == arrow-bold--right {
            $char: "\f10b";
        }
        @if $filename == arrow-bold--up {
            $char: "\f10c";
        }
        @if $filename == chat {
            $char: "\f10d";
        }
        @if $filename == checkmark {
            $char: "\f10e";
        }
        @if $filename == chevron--down {
            $char: "\f10f";
        }
        @if $filename == chevron--left {
            $char: "\f110";
        }
        @if $filename == chevron--right {
            $char: "\f111";
        }
        @if $filename == chevron--up {
            $char: "\f112";
        }
        @if $filename == clock {
            $char: "\f113";
        }
        @if $filename == close {
            $char: "\f114";
        }
        @if $filename == eye {
            $char: "\f115";
        }
        @if $filename == hamburger {
            $char: "\f116";
        }
        @if $filename == heart {
            $char: "\f117";
        }
        @if $filename == link {
            $char: "\f118";
        }
        @if $filename == marker {
            $char: "\f119";
        }
        @if $filename == phone {
            $char: "\f11a";
        }
        @if $filename == quote-opening {
            $char: "\f11b";
        }
        @if $filename == search {
            $char: "\f11c";
        }
        @if $filename == social--email {
            $char: "\f11d";
        }
        @if $filename == social--facebook {
            $char: "\f11e";
        }
        @if $filename == social--linkedin {
            $char: "\f11f";
        }
        @if $filename == social--twitter {
            $char: "\f120";
        }
        @if $filename == star {
            $char: "\f121";
        }
        @if $filename == user {
            $char: "\f122";
        }
        @if $filename == plus {
            $char: "\2b";
        }
        @if $filename == minus {
            $char: "-";
        }
    @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
    &:#{$insert} {
        @if $extend {
            @extend %icon;
        } @else {
            @include icon-styles;
        }
        content: icon-char($filename);
    }
}

.icon-arrow--down {
    @include icon(arrow--down);
}
.icon-arrow--left {
    @include icon(arrow--left);
}
.icon-arrow--right {
    @include icon(arrow--right);
}
.icon-arrow--up {
    @include icon(arrow--up);
}
.icon-arrow-alt--down {
    @include icon(arrow-alt--down);
}
.icon-arrow-alt--left {
    @include icon(arrow-alt--left);
}
.icon-arrow-alt--right {
    @include icon(arrow-alt--right);
}
.icon-arrow-alt--up {
    @include icon(arrow-alt--up);
}
.icon-arrow-bold--down {
    @include icon(arrow-bold--down);
}
.icon-arrow-bold--left {
    @include icon(arrow-bold--left);
}
.icon-arrow-bold--right {
    @include icon(arrow-bold--right);
}
.icon-arrow-bold--up {
    @include icon(arrow-bold--up);
}
.icon-chat {
    @include icon(chat);
}
.icon-checkmark {
    @include icon(checkmark);
}
.icon-chevron--down {
    @include icon(chevron--down);
}
.icon-chevron--left {
    @include icon(chevron--left);
}
.icon-chevron--right {
    @include icon(chevron--right);
}
.icon-chevron--up {
    @include icon(chevron--up);
}
.icon-clock {
    @include icon(clock);
}
.icon-close {
    @include icon(close);
}
.icon-eye {
    @include icon(eye);
}
.icon-hamburger {
    @include icon(hamburger);
}
.icon-heart {
    @include icon(heart);
}
.icon-link {
    @include icon(link);
}
.icon-marker {
    @include icon(marker);
}
.icon-phone {
    @include icon(phone);
}
.icon-quote-opening {
    @include icon(quote-opening);
}
.icon-search {
    @include icon(search);
}
.icon-social--email {
    @include icon(social--email);
}
.icon-social--facebook {
    @include icon(social--facebook);
}
.icon-social--linkedin {
    @include icon(social--linkedin);
}
.icon-social--twitter {
    @include icon(social--twitter);
}
.icon-star {
    @include icon(star);
}
.icon-user {
    @include icon(user);
}
