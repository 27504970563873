/**
* @file
* The standard styling for all buttons in the theme
*
* @example
* <button class="c-button">Call to action</button>
 */

.c-button {
  display: inline-block;
  position: relative;
  color: $color-black;
  transform: translate(-2px, -2px);
  padding: 13px 20px 12px;
  text-decoration: none;
  cursor: pointer;
  transition: $default-transition;
  font-weight: bold;
  text-align: center;

  & + & {
    margin-top: 2rem;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: $default-transition;
  }

  &:before {
    z-index: -1;
    background: $color-yellow;
    border: 2px solid $color-black;
  }

  &:after {
    z-index: -2;
    transform: translate(2px, 2px);
  }

  &:hover {
    color: $color-black;
    transform: translate(-4px, -4px);

    &:after {
      transform: translate(4px, 4px);
    }
  }

  &:active {
    transform: translate(0px, 0px);

    &:after {
      transform: translate(0px, 0px);
    }
  }
}

.c-button--secondary {
  &:before {
    background: white;
    border: 2px solid $color-black;
  }
}

.c-button--inverse {
  color: $color-white;
  &:hover {
    color: $color-white;
  }
  &:before {
    background: $color-black;
    border: 2px solid $color-black;
  }
}

.c-button--linkedin {
  color: $color-white;
  &:hover {
    color: $color-white;
  }
  &:before {
    background: $color-social--linkedin;
  }
  &:after {
    background: $color-black!important;
  }
  i {
    padding-right: 10px;
    vertical-align: baseline;
  }
}

.c-button--surfnet {
  color: $color-black;

  &:hover {
    color: inherit;
  }

  &:before {
    background: $color-social--surfnet;
  }

  &:after {
    background: $color-black!important;
  }
}

.c-button--icon {
  i {
    font-size: 1rem;
    margin-left: .5rem;
  }
}

.c-button:disabled,
.c-button.is-disabled {
  opacity: .3;
  pointer-events: none;
}
