.c-form-footer {
  display: flex;
  flex-direction: row;

  &--wide {
    flex-direction: column;
    gap: 2rem;
    .c-form-footer__action {
      margin: {
        top: unset;
        left: unset;
      }
    }
    .c-button {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.c-form-footer__description {
  flex: 5 0 0;
}

.c-form-footer__action {
  flex: 4 0 0;
  margin-left: auto;
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-top: 3rem;
  }
}
