.c-membership-type {
    margin-bottom: 2rem;
    width: 100%;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: column;
        -ms-flex-direction: row; // IE 11
    }
}

.c-membership-type.is-selected {
    .c-membership-type__footer {
        display: none;
    }

    .c-membership-type__name {
        font-size: 2rem;
        margin-bottom: 0;
    }

    .c-membership-type__seats {
        display: none;
    }

    .c-membership-type__price {
        display: none;
    }

    .c-membership-type__info {
        display: none;
    }

    .c-membership-type__content {
        flex-flow: unset;
        flex: unset;
    }

    .c-membership-type__adjustselection {
        display: inline-block;
    }

}

.c-membership-type__content {
    background: $color-white;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @include media-breakpoint-up(sm) {
        flex-flow: row nowrap;
        flex: 1;
    }
}

.c-membership-type__description {
    width: 100%;
}

.c-membership-type__name {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 500;
    margin-bottom: 1rem;
    transition: font-size 400ms ease;

    @include media-breakpoint-up(sm) {
        font-size: 3rem;
    }
}

.c-membership-type__seats {
    margin-bottom: 1rem;
    color: $color-grey--dark;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 400;

    @include media-breakpoint-up(sm) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0.5rem;
    }
}

.c-membership-type__price {
    text-align: center;

    @include media-breakpoint-up(sm) {
        text-align: left;
        font-size: 2.7rem;
    }

    @media screen and (max-width: 475px) {
      text-align: right;
    }
}

.c-membership-type__info {
    padding: 0 1rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
        text-align: left;
    }
}

.c-membership-type__specific {
    height: 3rem;
    margin-bottom: 2rem;
}

.c-membership-type__label {}

.c-membership-type__includes {
    display: none;
}

.c-membership-type__cta {
    width: 100%;
}

.c-membership-type__footer {
    width: 100%;
    min-width: 120px;
    text-align: center;
    margin: 1rem auto 0;

    @include media-breakpoint-up(sm) {
        width: auto;
        margin-left: 2rem;
    }
}

.c-membership-type__adjustselection {
    display: none;
}
