.c-signup-header {
    text-align: center;
    font-family: "Brown Pro", sans-serif;
}

.c-signup-landing {
    .c-signup-header {
        margin-bottom: 4rem;
        font-size: 4rem;
        font-weight: 400;

        &__corporate {
            display: flex;
            justify-content: center;
        }

        &__personal {
            display: flex;
            justify-content: center;
        }
    }
}

.c-signup-header__payoff {
    display: block;
    margin-top: 0.5rem;
    font-size: 1.7rem;
    font-weight: normal;
    font-family: "EB Garamond", serif;
}

.c-signup-landing {
    .c-signup-header__payoff {
        text-align: center;
        font-size: 2.6rem;
        line-height: 3.4rem;
    }
}
