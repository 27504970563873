.c-step {
  color: $color-black;
  text-decoration: none;
  display: flex;
  font-size: 1.2rem;
  line-height: 1;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }

  &:hover {
    color: $color-black;
  }

  &.is-uncompleted {
    color: $color-grey--light;
    pointer-events: none;

    .c-step__icon {
      border-color: $color-grey--light;
    }
  }

  &.is-completed {
    color: $color-green;

    .c-step__icon {
      border-color: $color-green;

      &:after {
        @extend %icon;
        content: icon-char(checkmark);
      }
    }
  }

  &.is-active {
    .c-step__text {
      display: inline-block;
    }
  }
}

.c-step__icon {
  position: relative;
  display: inline-flex;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 1px solid $color-black;
  align-items: center;
  justify-content: center;

  &:after {
    counter-increment: step-indicator;
    content: counter(step-indicator);
  }

  @include media-breakpoint-up(sm) {
    width: 3rem;
    height: 3rem;
  }
}

.c-step__text {
  display: none;
  padding-left: 1rem;
  align-self: center;

  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}
