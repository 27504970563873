.c-pricing-block {
  margin-bottom: 1rem;
}

.c-pricing-block__content {
  cursor: pointer;
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
}

.c-pricing-block__item {
  @include media-breakpoint-up(sm) {
    padding-bottom: 1em;
    width: calc(33.33% - .5rem);
  }

  @include media-breakpoint-up(md) {
    width: calc(33.33% - .75rem);
  }
}

.c-pricing-block__seats {
  margin-bottom: 0;
}
