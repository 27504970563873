.c-postcode-check {
}

.c-postcode-check__button {
  @include media-breakpoint-up(sm) {
    margin-top: 2.2rem;
  }
}

.c-postcode-check__loading {
}

.c-postcode-check__error {
}
