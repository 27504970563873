.c-subscription-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0 1rem;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
        padding: 9.4rem 0 1rem;
    }
}

.c-subscription-overview__group-toggle {
    margin-bottom: 12rem;
}

.c-subscription-overview__footnote {
    margin-top: auto;
    color: $color-grey;
    text-align: center;
    padding: 0 1rem;
}
