.c-inline-logo {
  margin: 4rem auto 1rem auto;
  text-align: center;

  img {
    max-height: 22px;

    @include media-breakpoint-up(sm) {
      max-height: 30px;
    }
  }
}
